import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import LoginApp from "./loginApp";

document.addEventListener("DOMContentLoaded", () => {
    const root = document.getElementById("login");

    if (!root) {
        return;
    }

    createRoot(root).render(
        <React.StrictMode>
            <LoginApp />
        </React.StrictMode>
    );
});
